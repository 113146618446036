import React from 'react';
import {Box, Typography, Divider} from '@mui/material';

const SpacingBox = () => (
    <Box height={24}/> // Adjust the height value to control the space between Boxes
);
const SpacingHorizontalBox = () => (
    <Box width={8}/> // Adjust the height value to control the space between Boxes
);

const Home = () => {
    return (
        <Box sx={{
            flexGrow: 1,
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'center',
            justifyContent: 'center'
        }} width="100%">
            <SpacingBox/>
            <SpacingBox/>
            <Typography variant="h4" component="h1" color="primary.main" align="center" gutterBottom>
                UpWrite AI: Editor and Proofreading Keyboard for iPhone, Android, and the Safari and Chrome Extensions.
            </Typography>
            <Typography variant="h6" component="h1" color="primary.main" align="center" gutterBottom>
                Writing assistance tool that uses artificial intelligence and natural language processing to identify
                and correct grammar, spelling, punctuation, and style errors in text. </Typography>
            <Box sx={{display: 'flex', justifyContent: 'center'}} width="100%">
                <div style={{display: 'flex', float: 'left', width: '100%'}}>
                    <div className="first" style={{
                        flex: '40%',
                        maxWidth: '100%',
                        float: 'left',
                        padding: '5px',
                        textAlign: "center"
                    }}>

                        <img src="/appicon_border.png" style={{borderRadius: '0%'}} alt="app logo" width="50%"/>
                        <center>Apple App Store & Google Play</center>
                    </div>
                    <div style={{
                        flex: '20%',
                        width: '256px',
                        float: 'left',
                        padding: '5px',
                        textAlign: "center"
                    }}></div>
                    <div style={{
                        flex: '40%',
                        maxWidth: '100%',
                        float: 'left',
                        padding: '5px',
                        textAlign: "center"
                    }}>
                        <img src="/appicon_extension.png" style={{borderRadius: '25%'}} alt="app logo" width="50%"/>
                        <center>Safari Extension & Chrome Web Store</center>
                    </div>
                </div>

            </Box>
            <SpacingBox/>
            <a href="https://www.producthunt.com/posts/up-write-ai-proofreading-keyboard-ext?utm_source=badge-featured&utm_medium=badge&utm_souce=badge-up&#0045;write&#0045;ai&#0045;proofreading&#0045;keyboard&#0045;ext"
               target="_blank">
                <img src="https://api.producthunt.com/widgets/embed-image/v1/featured.svg?post_id=413903&theme=light"
                     alt="Up&#0032;Write&#0032;AI&#0058;&#0032;Proofreading&#0032;Keyboard&#0032;Ext&#0046; - A&#0032;writing&#0032;assistant&#0032;detects&#0044;&#0032;corrects&#0032;errors&#0032;&#0038;&#0032;improves&#0032;text | Product Hunt"
                     width="250" height="54"/>
            </a>
            <SpacingBox/>
            <Typography variant="h6" component="h1" color="primary.main" align="center" gutterBottom>
                Dictate or Draft, Elevate (Up Write), Amaze, Share/Publish/Send/Print, Repeat!
            </Typography>
            <SpacingBox/>
            <SpacingBox/>
            <Box sx={{bgcolor: 'grayBackground.default', p: 4, borderRadius: 4, boxShadow: 4}} width="100%">
                <Typography variant="h6">
                    UpWrite AI proofreads your text to correct all mistakes and errors just before you POST it anywhere!
                    <Divider sx={{my: 2}}/>
                    UpWrite AI is a digital writing assistance tool that uses artificial intelligence and natural
                    language processing to identify and correct grammar, spelling, punctuation, and style errors in
                    text. Additionally, it can adjust the tone, clarity, engagement, and delivery of a piece of writing.
                    It's specifically designed for mobile users on both iOS and Android platforms and also for desktop
                    users as Safari and Chrome Extensions. This tool is ideal for writers, students, professionals, and
                    anyone looking to enhance the quality and clarity of their written communication. With a focus on
                    mobile optimization and voice-to-text (dictation) capabilities, UpWrite AI stands out as a premier
                    choice for those seeking top-tier writing assistance on the go.
                    <Divider sx={{my: 2}}/>
                    Choose UpWrite AI and embark on a journey of flawless and effective communication. Say what you mean
                    every single time. Say hello to confidence in every word and sentence you write, ensuring your
                    message is always received as intended.
                </Typography>
            </Box>
            {/* The Roles and Extensions */}
            <SpacingBox/>
            <Box sx={{bgcolor: 'primary.main', p: 4, borderRadius: 4, boxShadow: 4}} width="100%">
                <Typography variant="h6" gutterBottom color="white">
                    Our Difference - We Value and Fairly Charge Our Customers
                    <Divider sx={{my: 2}} color="white"/>
                    <Typography variant="h6" gutterBottom>
                        While most competing apps tie you down with subscriptions, we believe in a 'Pay as you Go'
                        philosophy. No hidden fees. No automatic renewals. We prioritize your needs, giving you the
                        power to decide when and for what you pay. Experience the freedom of only paying for what you
                        actually use.
                    </Typography>
                </Typography>
            </Box>
            <SpacingBox/>
            {/* All features section */}
            <Box sx={{bgcolor: 'grayBackground.default', p: 4, borderRadius: 4, boxShadow: 4}} width="100%">
                <Typography variant="h6" gutterBottom>
                    Feature Highlights
                    <Divider sx={{my: 2}}/>
                    <ul>
                        <li><b>Cutting-Edge AI Integration:</b> Accurately checks, corrects, and improves every aspect
                            of your writing from typos to semantics.
                        </li>
                        <li><b>Advanced Editing:</b> Ability to mold your text to desired tones, styles, and audiences,
                            ensuring versatility in your communication.
                        </li>
                        <li><b>Proofreading Keyboard Extension:</b> Works harmoniously across all apps on your phone,
                            ensuring consistent quality in all your writings. Is an embedded companion keyboard
                            extension that works in
                            Mail, Messenger, Messages, LinkedIn, X (Twitter), Gmail, etc.
                        </li>
                        <li><b>In-App Voice Input:</b> A voice-to-text tool designed for those on-the-go moments,
                            transforming your spoken words into polished written content.
                        </li>
                        <li><b>Educative Corrections:</b> Understand your mistakes as UpWrite AI not only corrects but
                            also explains, fostering continuous improvement in your writing skills.
                        </li>
                        <li><b>Multilingual Support:</b> While we shine in English, our AI understands and enhances
                            writing in multiple languages. Also, UpWrite AI embraces a global audience with its ability
                            to
                            recognize and synthesize voice in any language supported by your device.
                        </li>
                        <li><b>One-Click Apple Registration:</b> Seamless and speedy registration process using your
                            Apple account, secure, effortless, trustworthy, and password-less.
                        </li>
                        <li><b>Local Speech Processing:</b> UpWrite AI prioritizes user privacy and convenience,
                            performing speech recognition and synthesis directly on your device—no need to send voice
                            data to the cloud.
                        </li>
                        <li><b>Reliable Connectivity:</b> Designed for optimal performance in areas with poor internet
                            connections, UpWrite AI exchanges text-only data to ensure a smooth user experience.
                        </li>
                    </ul>
                </Typography>
            </Box>
            <SpacingBox/>
            <Box sx={{bgcolor: 'primary.main', p: 4, borderRadius: 4, boxShadow: 4}} width="100%">
                <Typography variant="h6" color="white">
                    Pricing & Word Tokens
                    <Divider sx={{my: 2}} color="white"/>
                    <ul>
                        <li><b>Trial:</b> Get started with 10,000 word tokens upon registration, giving you a
                            substantial feel of the app and keyboard prowess.
                        </li>
                        <li><b>Flexible Packs:</b> Purchase additional tokens as needed. Choose from <ul>
                            <li>40,000 Tokens for $1</li>
                            <li>200,000 Tokens for $3</li>
                            <li>500,000 Tokens for $6</li>
                        </ul>
                        </li>
                        <li><b>Tokens' Significance:</b> To give a perspective, 10,000 tokens can cover roughly 1 to 3
                            hours of conversation. They're the measure of AI's understanding and response capacity.
                        </li>
                        <li><b>Evergreen Tokens:</b> Once bought, your tokens never expire.
                        </li>
                    </ul>
                    <Divider sx={{my: 2}} color="white"/>
                    Language Models (AI) do not think in words or images, but in tokens. Word tokens are the essential
                    units used to measure the amount of work an AI has to do to understand statements and generate
                    answers.
                    <Divider sx={{my: 2}} color="white"/>
                    <Typography sx={{fontStyle: "italic"}} variant="body1" gutterBottom color="white">
                        Our business model revolves around these word tokens. We pay our AI engine provider based on
                        token usage, and similarly, we bill our customers for the tokens they consume, with a modest
                        markup for our efforts and services. We strive for fairness in our pricing and humbly ask our
                        users to support us: by purchasing our products, rating our app, and sharing it with friends and
                        colleagues.
                    </Typography>
                </Typography>

            </Box>
            <SpacingBox/>
            <Box sx={{bgcolor: 'grayBackground.default', p: 4, borderRadius: 4, boxShadow: 4, width: '100%'}}>
                <Typography variant="h6" gutterBottom>
                    Download the app by scanning QR Code
                    <Divider sx={{my: 2}}/>

                    <Box sx={{display: 'flex', justifyContent: 'center'}} width="100%">
                        <div style={{display: 'flex', float: 'left', width: '100%'}}>
                            <div className="first" style={{
                                flex: '40%',
                                maxWidth: '100%',
                                float: 'left',
                                padding: '5px',
                                textAlign: "center"
                            }}>
                                <img src="/apple_upwrite_qr_code.png" width="100%" alt="itunes"
                                     style={{borderRadius: '5%'}}/>
                                <center>Apple App Store</center>
                            </div>
                            <div style={{
                                flex: '20%',
                                width: '256px',
                                float: 'left',
                                padding: '5px',
                                textAlign: "center"
                            }}></div>
                            <div style={{
                                flex: '40%',
                                maxWidth: '100%',
                                float: 'left',
                                padding: '5px',
                                textAlign: "center"
                            }}>
                                <img src="/google_upwrite_qr_code.png" width="100%" alt="google play"
                                     style={{borderRadius: '5%'}}/>
                                <center>Google Play</center>
                            </div>
                        </div>
                    </Box>
                </Typography>
            </Box>
            <SpacingBox/>
            <Box sx={{bgcolor: 'primary.main', p: 4, borderRadius: 4, boxShadow: 4}} width="100%">
                <Typography variant="h6" color="white">
                    Or click on one of the links
                    <Divider sx={{my: 2}} color="white"/>
                    <Box sx={{display: 'flex', justifyContent: 'center'}}>
                        <ul>
                            <li className="first" style={{display: 'inline-block', marginRight: '20px'}}>
                                <a href="https://apps.apple.com/us/app/upwrite-proofreads-your-texts/id6467161876"
                                   target="_blank"
                                   rel="noopener noreferrer">
                                    <img src="/mac_app_store.svg" alt="macappstore"/>
                                </a>
                            </li>
                            <li style={{display: 'inline-block', marginRight: '18px'}}>
                                <a href="https://apps.apple.com/us/app/upwrite-ai-proofread-keyboard/id6463730152"
                                   target="_blank"
                                   rel="noopener noreferrer">
                                    <img src="/app_store.svg" alt="itunes"/>
                                </a>
                            </li>
                            <li style={{display: 'inline-block', marginRight: '18px'}}>
                                <a href="https://play.google.com/store/apps/details?id=com.answersolutions.upwriteai"
                                   target="_blank"
                                   rel="noopener noreferrer">
                                    <img src="/google_play.png" alt="google play" height={44}/>
                                </a>
                            </li>
                            <li style={{display: 'inline-block'}}>
                                <a href="https://chrome.google.com/webstore/detail/upwrite-ai-proofreading-t/dfmkhghfmnmpclilkafacfjoajlomjeo"
                                   target="_blank"
                                   rel="noopener noreferrer">
                                    <img src="/chrome_web_store.png" alt="chrome web store" height={42}/>
                                </a>
                            </li>
                        </ul>
                    </Box>
                </Typography>
            </Box>
        </Box>
    );
};

export default Home;