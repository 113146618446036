import React from 'react';
import {Box, Divider, Typography} from '@mui/material';

const SpacingBox = () => (
    <Box height={24} /> // Adjust the height value to control the space between Boxes
);

const Support = () => {
    const faq = [
        {
            question: 'Q: How can I contact customer support?',
            answer: 'A: You can contact us via email at support@answersolutions.net.'
        },
        {
            question: 'Q: What languages are supported for voice recognition and dictation by UpWrite AI Editor & Keyboard?',
            answer: 'A: UpWrite AI Editor & Keyboard supports voice recognition and dictation in any language that is supported by your device.'
        },
        {
            question: 'Q: Which languages can ChatGPT in UpWrite AI Editor & Keyboard correct and elevate?',
            answer: 'A: ChatGPT within UpWrite AI Editor & Keyboard primarily operates in English. While it can function in many other languages, we haven\'t tested them all. The features might work for most languages, but be aware that explanations of changes may sometimes be provided in English.'
        },
        {
            question: 'Q: Is UpWrite AI Editor & Keyboard available on all mobile devices?',
            answer: 'A: UpWrite AI Editor & Keyboard is available for download on modern iOS and Android devices.'
        },
        {
            "question": "Q: Which apps are compatible with the UpWrite AI Editor & Keyboard?",
            "answer": "A: UpWrite AI Editor & Keyboard is designed to work seamlessly with a wide range of popular apps for posting, messaging, emailing, and professional communications. This includes but isn't limited to: WhatsApp, Instagram, Facebook, Messenger, Twitter, Snapchat, LinkedIn, Slack, Gmail, Microsoft Outlook, Telegram, Signal, WeChat, Line, Viber, Trello, Skype for Business, Google Meet, Zoom Chat, Discord, and so on. Ensure you're delivering top-notch content, messages, and communications across all your favorite platforms with UpWrite AI Editor & Keyboard."
        },
        {
            question: 'Q: How do I purchase additional tokens?',
            answer: 'A: You can purchase additional tokens through the app. Go to Account Settings, and tap the \'Add Credit\' button. The price varies depending on the tier you choose: $1 for 40K, $3 for 200K, and $5 for 500K. You can also subscribe to the service to double the number of tokens for the same price, gain access to the history of your conversations, and more.'
        },
        {
            question: 'Q: Is my data safe with UpWrite AI Editor & Keyboard?',
            answer: 'A: UpWrite AI Editor & Keyboard takes your privacy and security seriously. We use all necessary standard tools and practices to protect your data from unauthorized access, use, or disclosure. We also have a comprehensive privacy policy that outlines our data practices.'
        },
        {
            question: 'Q: What kind of AI assistant does UpWrite AI Editor & Keyboard use?',
            answer: 'A: UpWrite AI Editor & Keyboard uses ChatGPT like LLM via the official OpenAI API.'
        },
        {
            question: 'Q: Can UpWrite AI Editor & Keyboard work offline?',
            answer: 'A: Some functions of UpWrite AI Editor & Keyboard, like voice-to-text capabilities, work offline. However, it requires an internet connection for AI-based text analysis and corrections.'
        },
        {
            question: 'Q: Can I test how UpWrite AI works without commitment?',
            answer: 'A: Of course, UpWrite AI Editor & Keyboard offers a trial of 10K (10,000) tokens that you get right after registration, which you can use to test out the app\'s features.'
        },
        {
            question: 'Q: How long will 10,000 tokens last, and is that considered a lot?',
            answer: 'A: The duration of AI interactions using 10,000 tokens varies based on factors such as the complexity of the analyzed texts. Roughly estimating, 10,000 tokens might allow for several hours of continuous use. To give some perspective, a standard novel typically contains between 60,000 to 100,000 words. Therefore, a 10,000-word segment is approximately one tenth of a novel or equivalent to roughly 1 to 3 hours of conversation. Importantly, once purchased, your tokens never expire!'
        },
        {
            question: 'Q: What if I have a suggestion or feedback for UpWrite AI Editor & Keyboard?',
            answer: 'A: We value your suggestions and feedback. Please send them to us via email at feedback@answersolutions.net.'
        },
        {
            question: 'Q: How do I enable the keyboard function on UpWrite AI Editor & Keyboard?',
            answer: 'A: To enable the keyboard on your mobile device, go to Settings > General > Keyboard > Keyboards > Add New Keyboard and select "UpWrite AI Editor & Keyboard". Then, grant it full access to enjoy all features. Ensure that you\'ve granted "Full Access" in settings to ensure it functions optimally.'
        },
        {
            question: 'Q: What does "Full Access" mode mean for the keyboard?',
            answer: 'A: "Full Access" mode allows the UpWrite AI Editor & Keyboard to operate at its full capacity. It\'s required to enable AI-based corrections, suggestions, and other advanced features within the keyboard. Your privacy remains our utmost priority, and no personal data is ever stored or shared.'
        },
        {
            question: 'Q: How does the keyboard analyze and correct text? Does it matter if the text is partially selected or if the cursor is positioned in the middle of the text?',
            answer: 'A: UpWrite AI Editor & Keyboard corrects and analyzes text from the cursor\'s current position back to the beginning of the input field or within any selected text. Simply position the cursor at the end of your text, its natural location after completing your input, or select the specific text you want analyzed, and let the tool refine your writing to a polished and professional standard.'
        }

    ];

    return (
        <Box sx={{display: 'flex', flexDirection: 'column'}}>
            <SpacingBox />
            <SpacingBox />
            <Typography variant="h4" mb={2}>FAQ And Support</Typography>
            {faq.map((item, index) => (
                <Box key={index} sx={{mb: 2}}>
                    <Typography variant="h6" mb={1}>{item.question}</Typography>
                    <Typography variant="body1">{item.answer}</Typography>
                    <Divider sx={{my: 2}}/>
                </Box>
            ))}
            <SpacingBox />
            <Typography variant="h4" mb={2}>Need Help?</Typography>
            <Typography variant="body1" mb={4}>Contact us via email at support @ answersolutions . net for
                assistance.</Typography>
        </Box>
    );
};

export default Support;
