import React from 'react';
import {Box, Button, styled} from '@mui/material';
import {Link, useLocation} from 'react-router-dom';

const StyledButton = styled(Button)(({theme, active}) => ({
    color: 'inherit',
    fontWeight: active ? 'bold' : 'normal',
    fontSize: active ? theme.typography.pxToRem(10) : theme.typography.pxToRem(10),
}));

const Footer = () => {
    const location = useLocation();

    const isActive = (pathname) => {
        return location.pathname === pathname;
    };

    return (
        <Box
            sx={{
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'space-between',
                padding: '1rem',
            }}
        >

            <Box sx={{flexGrow: 1, display: 'flex', justifyContent: 'center'}}>
                <StyledButton component={Link} to="https://answersolutions.net" color="inherit" target="_blank">
                    &copy; 2023 AnswerSolutions LLC.{' '}
                </StyledButton>
                <StyledButton component={Link} to="/" active={isActive('/')}>
                    Home
                </StyledButton>
                {/*<StyledButton component={Link} to="/pricing" active={isActive('/pricing')}>*/}
                {/*    Pricing*/}
                {/*</StyledButton>*/}
                {/*<StyledButton component={Link} to="/tutorial" active={isActive('/tutorial')}>*/}
                {/*    Tutorial*/}
                {/*</StyledButton>*/}
                <StyledButton
                    component={Link}
                    to="/privacy-policy"
                    active={isActive('/privacy-policy')}
                >
                    Privacy Policy
                </StyledButton>
                <StyledButton
                    component={Link}
                    to="/user-agreement"
                    active={isActive('/user-agreement')}
                >
                    User Agreement
                </StyledButton>
                <StyledButton component={Link} to="/support" active={isActive('/support')}>
                    Support
                </StyledButton>
            </Box>
        </Box>
    );
};

export default Footer;
